.full-centered {
  display: flex;
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  align-items: center;
  justify-content: center;
}

.sidebar-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  > img {
    max-width: 60px;
    max-height: 20px;
  }
}


.usuarioAcciones {
  display: flex;
  align-items: center;
}

.flex-grow {
  flex-grow: 1;
}

.main-sidebar {
  z-index: 100 !important;
}

.antd-input-row {

  > label {
    font-size: 8px;
    padding: 0 4px;
    display: flex;
    margin: 0;
    align-items: center;
    text-transform: uppercase;
    text-align: center;
    width: 70px;
    justify-content: center;
    border: 1px solid #d9d9d9;
    border-right: 0;
    background-color: white;
    border-radius: 5px 0px 0px 5px;
  }
}

.row-gutters-md {

  > * {
    padding: 1vw !important;
  }
}