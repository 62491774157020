.my-chart tspan {
    display: none;
}

.my-chart-leyend tspan{
    font-weight: 100;
}

.my-chart-leyend2 .apexcharts-legend.apx-legend-position-bottom.apexcharts-align-center {
    inset: auto 0px 15px 20px !important;
}
