.caritaStyle {
  margin-top:20px;
  list-style: none;

  margin-bottom: 50px;
}
.caritaStyle li {
  margin-left:5px;
  margin-right: 5px;
  text-align: center;
}
.caritaStyle li img{
  display:block;
}
h4.dblock {
  display: block;
  text-align: center;

}

.rs-control .rs-range-color {
  background-color: #007bff;
}
.rs-control .rs-path-color {
  background-color: #ffffff;
}
.rs-control .rs-handle{
  background-color: #ffffff;
}
.rs-control .rs-bg-color{
  background-color: white;
}
.rs-control .rs-handle  {
  background-color: #ffffff;
  border: 2px solid #2e8df3;
}

.rs-control .rs-border  {
  border-color: transparent;
}

.rs-control .rs-path-color {
  background-color: #d7d7d7;

}